<template>
    <div class="flex-1 pb-4">
        <ModalAddPaymentMethod
            v-if="modalAddPaymentMethodVisible"
            :modalPaymentMethod="modalPaymentMethod"
            @refresh="getAll"
            @close="modalPaymentMethod = false"
            @closed="modalAddPaymentMethodVisible = false"
        />

        <PaymentMethodEntry
            v-for="method in paymentMethods"
            :key="method.uuid"
            :paymentMethod="method"
            @refresh="getAll"
        />
        <div v-if="paymentMethods.length === 0" v-loading="$wait.is('loading')" class="card min-h-full" element-loading-spinner="el-custom-spinner">
            <div v-if="paymentMethods.length === 0 && !$wait.is('loading')" class="flex-1 flex flex-col items-center py-10">
                <img src="@/assets/images/undraw_empty_result.svg" class="mb-10" alt="" width="200px">
                <p>
                    {{ $t('common.sorry_no_results_for_that_data') }}
                </p>
            </div>
        </div>
        <div class="flex justify-center">
            <el-button type="primary" :loading="$wait.is('loading')" :disabled="!userCan('update settings')" @click="showAddModal">
                {{ $t('common.add') }}
            </el-button>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        ModalAddPaymentMethod: () => import(/* webpackChunkName: "AcceptedPaymentMethods/ModalAddPaymentMethod" */ './ModalAddPaymentMethod'),
        PaymentMethodEntry:    () => import(/* webpackChunkName: "AcceptedPaymentMethods/PaymentMethodEntry" */ './PaymentMethodEntry'),
    },

    data() {
        return {
            paymentMethods:               [],
            modalAddPaymentMethodVisible: false,
            modalPaymentMethod:           false,
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'payment_methods');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading');

            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/settings/payment_methods`);
                this.$wait.end('loading');
                this.paymentMethods = data;
            } catch (error) {
                this.$wait.end('loading');
            }
        },

        async showAddModal() {
            this.modalAddPaymentMethodVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalPaymentMethod = true;
        },
    },
};
</script>
